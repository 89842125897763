.services_row {
    display: flex;
    width: 1300px;
    justify-content: space-between;
}

.services_title_cntr {
    width: 100%;
    text-align: left;
    /* margin-left: 160px; */
}

.cntr_login_widget {
    width: 420px;
    background-color: #333B5F;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    box-sizing: border-box;
}

.login_widget_button {
    /* Frame 3 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 30px;

    width: 360px;
    height: 45px;
    border-radius: 0;

    /* Gold */
    background: #A78957;
    box-sizing: border-box;
    border: none;

}

.services_button {
    /* Frame 3 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
    /* Gold */
    background: #A78957;
    width: 1300px;
    border-radius: 0;
    border: none;
}

.login_widget_footer_text {
    /* Δίνω τη συγκατάθεσή μου για την επεξεργασία προσωπικών δεδομένων */

    width: 360px;
    height: 36px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    /* or 18px */
    text-align: center;

    color: #DCDCDC;

}

.login_widget_input {
    /* Frame 5 */
    outline: none;
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 15px;
    gap: 10px;

    width: 360px;
    height: 45px;
    color: #fff;

    border: 1px solid #DCDCDC;
    background: none;
}

.login_widget_input::placeholder {
    /* Email */



    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* identical to box height */

    /* White */
    color: #F5F5F5 !important;

}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #333B5F inset !important;
}


.login_widget_title {
    margin-top: 103px;
    /* Online ΔικηγΟρος Chat */
    width: 333px;


    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    text-align: center;
    text-transform: uppercase;

    /* White */
    color: #F5F5F5;


}

.services_row_item_text1 {
    position: absolute;
    left: 30px;
    top: 197px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    text-transform: uppercase;

    text-align: left;
    /* White */
    color: #F5F5F5;

}

.services_row_item_text2 {
    position: absolute;
    left: 30px;
    top: 248px;
    width: 570px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */
    text-align: left;
    color: #DCDCDC;
/* Rectangle 8 */

}