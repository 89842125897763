

.subtitle_boking {
    /* Δίνω τη συγκατάθεσή μου για την επεξεργασία προσωπικών δεδομένων */

    margin-top: 20px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */
    text-align: center;

    color: #DCDCDC;


}



@media (min-width: 900px) {
    .btn_booking {
        /* Frame 3 */
    
        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px 30px;
        /* Gold */
        background: #A78957;
        width: 740px;
        box-sizing: border-box;
        margin-top: 20px;
    }

    .row_input_booking {
        display: flex;
        gap: 20px;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }

    .cntr_booking {


        box-sizing: border-box;
        width: 1300px;
        height: 558px;

        /* Gold */
        border: 10px solid #A78957;

        position: relative;
    }

    .subcntr_booking {
        /* Rectangle 10 */

        position: absolute;
        width: 1260px;
        height: 518px;
        left: calc(50% - 1260px/2);
        top: calc(50% - 518px/2);

        /* Blue */
        background: #333B5F;

    }

    .title_booking {
        /* H2 */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 46px;
        line-height: 56px;
        /* identical to box height */
        text-transform: uppercase;

        /* White */
        color: #F5F5F5;
        margin-top: 60px;
    }

    .cntr_booking_input_mob {
        display: none;
    }

    .booking_widget_input {
        /* Frame 5 */
        outline: none;
        box-sizing: border-box;
    
        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 14px 15px;
        gap: 10px;
    
        width: 360px;
        height: 45px;
        color: #fff;
    
        border: 1px solid #DCDCDC;
        background: none;
    }

    .booking_bottom_cntr {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        width: 1300px;
      }
}



@media (max-width: 900px) {
    .booking_bottom_cntr {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* width: 320px; */
        padding: 0 20px;
        margin-top: 15px;
      }
    .btn_booking {
        /* Frame 3 */
    
        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px 30px;
        /* Gold */
        background: #A78957;
        width: 280px;
        box-sizing: border-box;
        margin-top: 20px;
    }

    .booking_widget_input {
        /* Frame 5 */
        outline: none;
        box-sizing: border-box;
    
        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 14px 15px;
        gap: 10px;
    
        width: 100%;
        height: 45px;
        color: #fff;
    
        border: 1px solid #DCDCDC;
        background: none;
    }
    .booking_widget_input::placeholder {
        /* Email */
    
    
    
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        /* identical to box height */
    
        /* White */
        color: #F5F5F5 !important;
    
    }
    .cntr_booking_input_mob {
        padding: 22px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .title_booking {

        /* H2 mobile */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        /* identical to box height */
        text-transform: uppercase;

        /* White */
        color: #F5F5F5;
    }

    .row_input_booking {
        display: none !important;
    }

    .cntr_booking {


        box-sizing: border-box;
        width: 340px !important;
        /* height: 558px; */

        /* Gold */
        border: 10px solid #A78957;

        position: relative;

        
    }

    .main_cntr_booking {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .subcntr_booking {
        /* Rectangle 10 */

        /* position: absolute; */
        width: 320px;
        padding: 60px 0;
        /* Blue */
        background: #333B5F;

    }
}