/* home.css */
@import '../../static/css/vars.css';





.logo {
  width: 64px;
  height: auto;
  position: absolute;
  top: 0px;
  left: 0;
}

.nav-menu {
  display: flex;
  gap: 20px;
  font-size: 1.2em;
  width: 1300px;
  align-items:flex-start;

  /* background: linear-gradient(to bottom, #18171e, #A9A4A1); */
  /* background: #221817ba */
}

.nav-item {
  cursor: pointer;
  
  font-size: 14px;
  font-family: "Inter", sans-serif;
}

.nav-item:hover {
  text-decoration: underline;
}


.nav-menu-mobile-services {
  display: none;
  /* По умолчанию меню скрыто */
}

@media (min-width: 900px) {
  .svg_globus {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.cntr_menu {
  margin-top: 31px;
  margin-left: 158px;
  display: flex;
  gap: 40px;
}


@media (max-width: 900px) {
  .svg_globus {
    /* transform: translateY(-40%); */
    position: absolute;
    right: 65px;
    top: 25%;
    cursor: pointer;
    display: flex;
  }

  .nav-menu-mobile-services {
    display: block;
    /* Показываем меню только на устройствах с шириной до 900 пикселей */
    text-align: right;
    width: 100%;
    height: 14vh;
    /* background: linear-gradient(to bottom, #18171e, #A9A4A1); */
    background-color: #18171e;
    position: relative;
  }

  .nav-menu {
    display: none;
  }

 

  .burger-menu {
    /* margin-right: 10px;
    position: absolute;
    top: 20%; */
    right: 10px !important;
  }
}

.burger-menu {
  margin-right: 10px;
  position: absolute;
  top: 20%;
  right: 70px;
}






.svg_out {
  position: absolute;
  right: 25px;
  cursor: pointer;
  display: flex;
}




.language-code {
  font-size: 14px;
  font-family: "Inter", sans-serif;

}

.cntr_block2 {
  background-color: #d4b43d;
  height: 100vh;
  width: 100%;
}

.logo_menu {
  width: 64px;
  height: auto;
}





