/* lawyerForm.css */
.lawyer-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 25vh;
    height: 80vh;
    width: 100%;
    background-color: #F8F8FA;
}

.lawyer-form-container h2 {
    margin-bottom: 20px;
}

.lawyer-form-container input,
.lawyer-form-container textarea {
    width: 100%;
    /* max-width: 400px; */
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.lawyer-form-container button {
    padding: 10px 20px;
    border: none;
    background-color: #18171e;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.lawyer-form-container-fields {
    padding: 20px;
}

.lawyer-form-container button:hover {
    background-color: var(--accent-color);
}
