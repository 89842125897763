

@media (max-width: 900px) {
    .cntr_instruction {
        /* width: 320px; */
        overflow: hidden;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .row_instruction {
    }

    .cntr_instruction_item {
        /* Rectangle 8 */
    
        box-sizing: border-box;
        width: 320px;
        /* height: 280px; */
    
        /* Blue */
        border: 1px solid #333B5F;
        text-align: left;
    
        padding: 30px;
    
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
    }

    .instruction_widget_button {
        /* Frame 3 */
    
        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 14px 30px;
    
        width: 360px;
        height: 45px;
        border-radius: 0;
    
        /* Gold */
        background: #A78957;
        box-sizing: border-box;
        margin-top: 20px;
    
    }
}

@media (min-width: 900px) {
    .instruction_widget_button {
        /* Frame 3 */
    
        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 14px 30px;
    
        width: 1300px;
        height: 45px;
        border-radius: 0;
    
        /* Gold */
        background: #A78957;
        box-sizing: border-box;
        margin-top: 40px;
    
    }

    .cntr_instruction {
        width: 1300px;
    }

    .row_instruction {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        width: 1300px;
    }

    .cntr_instruction_item {
        /* Rectangle 8 */
    
        box-sizing: border-box;
        width: 630px;
        height: 280px;
    
        /* Blue */
        border: 1px solid #333B5F;
        text-align: left;
    
        padding: 30px;
    
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}





.text_intruction_item {
    /* Στην ιστοσελίδα μας μπορείτε να χρησιμοποιήσετε την online συμβουλή με τον εικονικό βοηθό. Για αυτό κλικ στο κουμπί "Online Δικηγόρος Chat". Θα χρειαστεί να πληρώσετε μια συνδρομή, η οποία θα ισχύει για 24 ώρες. */



    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */

    color: #A0A0A0;



}

.title_instruction_item {
    /* Online ΔικηγΟρος Chat */



    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    text-transform: uppercase;

    /* Black */
    color: #1A1A1A;


}

.instruction_btn_txt {
    /* Online Δικηγόρος Chat */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: #F5F5F5;

}