.bg_mobile_menu {
    position: absolute;
    width: 100vw;
    height: 80vh;
    background-color: black;
    opacity: 0.5;
    z-index: 999;
}

.mob_menu_header {
    margin-top: 21px;
    width: 100%;
    height: 100px;
    position: relative;
    margin-left: 20px;
}

.mob_menu_cross {
    position: absolute;
    right: 40px;
    top: 20px;
    cursor: pointer;
}

.mob_menu_nav-item {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-transform: uppercase;

    /* White */
    color: #F5F5F5;

}

.mob_menu_cntr_items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: start;
    margin-left: 20px;
}

.mob_menu_cntr_btn {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
}