@media (max-width: 900px) {
  .feedback-container {
    /* width: 100vw; */
    overflow: hidden;
    /* height: 559px; */
    display: flex;
    flex-direction: column;
    /* justify-content: center;*/
    align-items: center; 
    /* background-color: #fff; */
    box-sizing: border-box;
    /* width: 1310px; */
    margin-left: 10px;
    margin-top: 80px;
    /* margin: 160px 0 166px 0; */
  }

  .feedback-container h1 {
    margin-top: 120px;
    font-size: 2em;
  }

  .img_wellcome {
    display: none;
  }

  .img_wellcome_mob {
    width: 320px;
    z-index: 1000;
  }

  .wellcome_square {
    /* Image - Close up lawyer businessman working or reading lawbook in office workplace for consultant lawyer */

    position: absolute;
    width: 161px;
    height: 140px;
    right: -10px;
    top: -10px;

    /* Gold */
    background: #A78957;
    z-index: -1;

  }

  .title1_wellcome {
    /* width: 103px; */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    text-transform: uppercase;

    /* Gold */
    color: #A78957;


  }

  .title1_wellcome2 {
    /* ΚαλΩς Ηρθατε στην DIKIGOROS ONLINE */

    margin-top: 20px;

    /* H2 mobile */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    text-transform: uppercase;
    display: block;
    /* Blue dark */
    color: #0A1424;
  }

  .text_wellcome {

    /* text mobile */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */

    color: #A0A0A0;

    width: 320px;


  }

  .feedback-carousel {
    text-align: left;
    width: 320px;
  }

  .feedback-controls {
    margin-top: 40px !important;
  }
}

@media (min-width: 900px) {
  .text_wellcome {
    /* Στην DIKIGOROS ONLINE, φέρνουμε την επανάσταση στην παροχή νομικών υπηρεσιών, προσφέροντας εύκολη και άμεση πρόσβαση σε εξειδικευμένους δικηγόρους μέσω διαδικτύου. Είμαστε αφοσιωμένοι στο να παρέχουμε στους πολίτες της χώρας μας αξιόπιστη νομική υποστήριξη, οποιαδήποτε στιγμή την χρειαστούν. Προσφέρουμε την άμεση και τεκμηριωμένη απάντηση που ζητάτε, ακόμη και πριν αναθεσετε την ΥΠΟΘΕΣΗ σε ένα απο τους εξειδικευμένους δικηγόρους μας, μέσω της καινοτόμου διαδικτυακής υπηρεσίας μας Chatbot. Μην αφήνετε τα νομικά σας προβλήματα να σας ταλαιπωρούν. Επικοινωνήστε μαζί μας σήμερα και επιτρέψτε μας να σας βοηθήσουμε να βρείτε τη λύση που χρειάζεστε. Είμαστε εδώ για εσάς, κάθε στιγμή της ημέρας, κάθε ημέρα της εβδομάδας. DIKIGOROS ONLINE - Νομικές Υπηρεσίες Παντού και Πάντα. */

    /* position: absolute; */
    width: 600px;
    height: 360px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */

    color: #A0A0A0;


  }

  .title1_wellcome2 {
    /* ΚαλΩς Ηρθατε στην DIKIGOROS ONLINE */

    /* H2 */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 46px;
    line-height: 56px;
    text-transform: uppercase;

    /* Blue dark */
    color: #0A1424;


  }

  .title1_wellcome {
    /* ΠΟΙΟΙ ΕΙΜΑΣΤΕ */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    text-transform: uppercase;

    /* Gold */
    color: #A78957;
  }

  .feedback-container {
    width: 100vw;
    height: 559px;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    /* background-color: #fff; */
    box-sizing: border-box;
    width: 1310px;
    margin-left: 10px;
    margin: 160px 0 166px 0;
  }

  .feedback-container h1 {
    margin-top: 120px;
    font-size: 2em;
  }

  .img_wellcome {

    width: 650px;
    height: 543px;
    z-index: 1000;
  }

  .img_wellcome_mob {
    display: none;
  }

  .wellcome_square {
    /* Image - Close up lawyer businessman working or reading lawbook in office workplace for consultant lawyer */

    position: absolute;
    width: 325px;
    height: 255px;
    right: -10px;
    top: -10px;

    /* Gold */
    background: #A78957;
    z-index: -1;

  }

  .feedback-carousel {
    text-align: left;
    /* width: 50%; */
    /* height: 100%; */
  }
}



.feedback-slide {
  display: none;
  text-align: center;
  transition: opacity 0.5s ease-in-out;
  background-color: #45405a;
  padding: 20px;
  border-radius: 10px;
}

.feedback-slide.active {
  display: block;
}

.feedback-slide.left {
  animation: slideInRight 2s forwards;
}

.feedback-slide.right {
  animation: slideInLeft 2s forwards;
}

.feedback-text {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.feedback-author {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.feedback-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.feedback-name {
  font-size: 1em;
  font-weight: bold;
}

.feedback-controls {
  width: 50%;
  position: relative;
}

.feedback-arrow {
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
}





.title1_line {
  /* Line 6 */
  width: 101px;
  height: 0px;
  /* Gold */
  border: 1px solid #A78957;
  transform: translateX(-1.5px);
  margin-top: 10px;
}







@media (max-width: 768px) {
  .feedback-container {
    padding: 10px;
  }

  .feedback-container h1 {
    font-size: 1.5em;
  }

  .feedback-text {
    font-size: 1em;
  }

  .feedback-author {
    flex-direction: column;
  }

  .feedback-avatar {
    margin-bottom: 5px;
    margin-right: 0;
  }

  .feedback-controls {
    width: fit-content;

  }
}


@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}