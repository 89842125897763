.advantage_text {
    /* ΕΥκολη ΠρΟσβαση */

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);


    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    text-transform: uppercase;

    /* Black */
    color: #1A1A1A;


}







@media (max-width: 900px) {
    .advantage_item_text {
        width: 300px;
        /* Με την διαδικτυακή μας πλατφόρμα, μπορείτε να κλείσετε ραντεβού, να λάβετε συμβουλές και να συνομιλήσετε με δικηγόρο εύκολα και γρήγορα. */
    
    
    
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */
        text-align: center;
    
        color: #A0A0A0;
        margin-top: 20px;
    
    }

    .cntr_advantage {
        /* width: 330px; */
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 160px;

    }

    .cntr_advantage2 {
        margin-top: '60px'
    }

    .advantage_item_cntr {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media (min-width: 900px) {
    .advantage_item_text {
        width: 300px;
        /* Με την διαδικτυακή μας πλατφόρμα, μπορείτε να κλείσετε ραντεβού, να λάβετε συμβουλές και να συνομιλήσετε με δικηγόρο εύκολα και γρήγορα. */
    
    
    
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */
        text-align: center;
    
        color: #A0A0A0;
    
    
    }

    .advantage_item_cntr {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .cntr_advantage {
        width: 1300px;
        margin-top: 160px;
    }

    .cntr_advantage2 {
        display: flex;
        justify-content: space-between;
        margin-top: 60px;
    }
}