/* profile.css */
.profile-container {
    padding: 20px;
    /* background-color: #f9f9f9; */
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 70vh;
    width: 80vw;
    text-align: left;
}

.profile-item {
    margin-bottom: 15px;
}

.profile-item label {
    font-weight: bold;
}


