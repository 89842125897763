.instruction_page_container {
    position: relative;
    display: flex;
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    /* Измените это значение в зависимости от ваших нужд */
    background-repeat: no-repeat;
    /* background: linear-gradient(79.27deg,#0d0d16 44.61%,rgba(255,255,255,0) 96.38%); */

    background-image:
        url('../../static/img/bg_chat.png');
}

.bg_1 {
    background: linear-gradient(to right, #161A38, #777A7B);
    z-index: 0;
    opacity: 0.7;
}

@media (max-width: 900px) {
    .main-container {
        display: flex;
        flex-direction: column-reverse;
        height: 80vh;
        align-items: center;
    }

    .right-block {
        position: absolute !important;
        bottom: 15vh;
        left: 0;
        width: 100% !important;
        margin: 0 !important;
        box-sizing: border-box !important;
    }

    .left-block-instruction {
        margin-top: 0 !important;
        padding: 10px !important;
        font-size: 16px !important;
        width: 80%;
    }

    .instruction_main_text {
        font-size: 40px !important;
        width: 340px !important;
        
    }

    .instruction_main_subtext {
        width: 340px !important;
    }
}

.left-block-instruction {
    margin-top: 20vh;
    flex: 1;
    padding: 50px calc(50% - 650px);
    text-align: left;
    color: #FDD034;
    font-size: 46px;
    z-index: 0;
}

.instruction_main_text {
    font-size: 1em;
    width: 540px;
    display: block;

    /* Η αναζητηση του δικαIου */

    /* H1 */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 70px;
    line-height: 110%;
    /* or 77px */
    text-transform: uppercase;

    /* White */
    color: #F5F5F5;


}

.instruction_main_subtext {
    margin-top: 20px;
    font-size: 16px;
    /* Η εμπειρία μας, η γαλήνη σας. 24/7 κοντά σε εσάς και τα προβλήματα σας. */

    /* position: absolute; */
    width: 856px;
    height: 60px;
    /* left: 70px;
top: 460px; */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    /* or 30px */

    color: #A0A0A0;
    display: block;

}

.right-block {
    display: flex;
    flex: 1;
    padding: 20px;
    margin-top: 65vh;
    margin-left: 35vw;
    gap: 20px;
    z-index: 1;
}

.left-block-instruction h1 {
    font-size: 24px;
    font-weight: bold;
}

.right-block img {
    max-width: 100%;
    height: auto;
    filter: drop-shadow(5px 5px 10px rgba(38, 34, 43, 0.5));
    border-radius: 10px;
    /* box-shadow: 0 0 25px rgba(239, 174, 159, 0.5); */

}

.button_main {
    border-radius: 7px;
    padding: 10px 10px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

/* Прозрачная кнопка с бордером */
.button-transparent {
    /* background-color: transparent;
    border: 1px solid #221817;
    color: #221817; */
}

.btn_online_txt {
    /* Online Δικηγόρος Chat */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: #F5F5F5;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.btn_online {
    /* Frame 3 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
    gap: 10px;

    /* position: absolute; */
    width: 214px;
    height: 57px;

    /* Gold */
    background: #A78957;
    box-sizing: border-box;
    cursor: pointer;

}

.btn_booling_txt {
    /* Κράτηση 24/7 */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: #292929;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.btn_booling {
    /* Frame 4 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
    /* gap: 10px; */

    /* position: absolute; */
    width: 154px;
    height: 57px;
    /* left: 304px; */
    /* top: 570px; */

    background: #F5F5F5;
    box-sizing: border-box;

}

/* Залитая кнопка с белым текстом */
.button-filled {
    background-color: #221817;
    border: 1px solid #221817;
    color: white;
}

.button-transparent:hover,
.button-filled:hover {
    box-shadow: 0 3px 5px #FDD034;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}



.textarea_modal {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}


.modal_cntr_btns {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.consent-container {
    display: flex;
    height: 30px;
    align-items: center;
}

.cntr_line {
    width: 100%;
    height: 0px;
    top: 10px;
    position: absolute;
    z-index: 1000;
}

.cntr_line2 {
    height: 0px;
    width: 100%;
    top: 90px;
    position: absolute;
    z-index: 1000;
}

.svg_line {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}