.bg_1 {
    background: linear-gradient(to right, #161A38, #777A7B);
    z-index: 0;
    opacity: 0.7;
}

@media (min-width: 900px) {
    .modal_bottom_text {

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        /* or 18px */
        text-align: center;
    
        color: #DCDCDC;
        /* word-wrap: break-word; */
        width: 330px;
    
        /* margin-top: 15px;
        margin-bottom: 30px; */
    
    }
    .main_subtext_page {
        margin-top: 20px;
        font-size: 16px;
        /* Η εμπειρία μας, η γαλήνη σας. 24/7 κοντά σε εσάς και τα προβλήματα σας. */

        /* position: absolute; */
        width: 320px;
        height: 60px;
        /* left: 70px;
    top: 460px; */

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        /* or 30px */

        color: #A0A0A0;
        display: block;

    }

    .btn_booking_page {
        /* Frame 4 */

        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px 30px;
        /* gap: 10px; */

        /* position: absolute; */
        width: 154px;
        height: 57px;
        /* left: 304px; */
        /* top: 570px; */

        background: #F5F5F5;
        box-sizing: border-box;
        cursor: pointer;
    }

    .svg_line {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .main-container {
        z-index: 0;
        position: relative;
        display: flex;
        height: 100vh;
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        /* background: linear-gradient(79.27deg,#0d0d16 44.61%,rgba(255,255,255,0) 96.38%); */

        background-image:
            url('../../static/img/bg_big.png');
    }

    .main_text_page {
        position: relative;
        font-size: 1em;
        width: 540px;
        display: block;

        /* Η αναζητηση του δικαIου */

        /* H1 */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 70px;
        line-height: 110%;
        /* or 77px */
        text-transform: uppercase;

        /* White */
        color: #F5F5F5;
    }
}

.modal_bottom_cntr {
    display: flex;
    justify-content: space-between;
    align-items:center;
    margin-top: 20px;
    gap: 10px
}

.modal_checkbox {
    margin: 0 !important;
    width: 15px !important;
    height: 15px !important;
}

.svg_close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

@media (max-width: 900px) {
    .modal_bottom_cntr {
        margin-top: 20px;
    }
    .modal_bottom_text {

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        /* or 18px */
        text-align: center;
    
        color: #DCDCDC;
        /* word-wrap: break-word; */
        width: 280px;
    
    }

    .modal-content {
        width: 95% !important;
        padding: 20px;
        box-sizing: border-box;
    }


    .main_subtext_page {
        margin-top: 20px;
        font-size: 16px;

        width: 305px;
        height: 42px;

        /* text mobile */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;



        color: #A0A0A0;
        display: block;

    }

    .btn_booking_page {
        /* Frame 4 */

        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px 30px;
        /* gap: 10px; */

        /* position: absolute; */
        width: 150px;
        height: 57px;
        /* left: 304px; */
        /* top: 570px; */

        background: #F5F5F5;
        box-sizing: border-box;

    }

    .svg_line {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 360px !important;
    }

    .main-container {
        width: 100vw;
        overflow: hidden;
        display: flex;
        flex-direction: column-reverse;
        height: 100vh;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image:
            url('../../static/img/bg_mob.png');

        font-family: 'Inter';
        font-style: normal;
    }

    .right-block {
        position: absolute !important;
        bottom: 15vh;
        left: 0;
        width: 100% !important;
        margin: 0 !important;
        box-sizing: border-box !important;
    }

    .left-block {
        margin-top: 0 !important;
        padding: 10px !important;
        font-size: 16px !important;
        /* width: 80%; */
    }

    .main_text_page {
        margin-top: 268px;
        width: 360px;
        display: block;
        /* H1 mobile */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 110%;
        /* or 44px */
        text-transform: uppercase;

        color: #F5F5F5;
    }
}

.left-block {
    margin-top: 20vh;
    flex: 1;
    padding: 50px calc(50% - 650px);
    text-align: left;
    color: #FDD034;
    font-size: 46px;
    z-index: 0;
}





.right-block {
    display: flex;
    flex: 1;
    padding: 20px;
    margin-top: 65vh;
    margin-left: 35vw;
    gap: 20px;
    z-index: 1;
}

.left-block h1 {
    font-size: 24px;
    font-weight: bold;
}

.right-block img {
    max-width: 100%;
    height: auto;
    filter: drop-shadow(5px 5px 10px rgba(38, 34, 43, 0.5));
    border-radius: 10px;
    /* box-shadow: 0 0 25px rgba(239, 174, 159, 0.5); */

}

.button_main {
    border-radius: 7px;
    padding: 10px 10px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

/* Прозрачная кнопка с бордером */
.button-transparent {
    /* background-color: transparent;
    border: 1px solid #221817;
    color: #221817; */
}

.btn_online_txt {
    /* Online Δικηγόρος Chat */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: #F5F5F5;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.btn_online {
    /* Frame 3 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
    gap: 10px;

    /* position: absolute; */
    width: 214px;
    height: 57px;

    /* Gold */
    background: #A78957;
    box-sizing: border-box;

}

.btn_booling_txt {
    /* Κράτηση 24/7 */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: #292929;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}



/* Залитая кнопка с белым текстом */
.button-filled {
    background-color: #221817;
    border: 1px solid #221817;
    color: white;
}

.button-transparent:hover,
.button-filled:hover {
    box-shadow: 0 3px 5px #FDD034;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #333B5F;
    max-width: 420px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-content h2 {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    text-align: center;
    text-transform: uppercase;

    /* White */
    color: #F5F5F5;


}

.modal-content label {
    display: block;
    margin-top: 10px;
    color: #18171e;
    text-align: left;
}

.modal-content textarea {
    resize: none;
    padding-top: 15px;
    height: 90px;
}

.modal-content input,
textarea {
    /* Frame 5 */

    box-sizing: border-box;

    margin-top: 15px;

    width: 360px;
    height: 45px;

    border: 1px solid #DCDCDC;

    /* Inside auto layout */
    flex: none;
    order: 0;
    /* align-self: stretch; */
    flex-grow: 0;
    background: transparent;

    /* Email */



    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* White */
    color: #F5F5F5;

    padding-left: 15px;

}

input:focus {
    outline: none;
}

textarea:focus {
    outline: none;
}

.modal-content input::placeholder {
    color: #F5F5F5;
}

.modal-content textarea::placeholder {
    color: #F5F5F5;
}





.modal_cntr_btns {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.modal_btn {
    /* Frame 3 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 30px;
    gap: 10px;

    width: 360px;
    height: 45px;

    /* Gold */
    background: #A78957;

    /* Inside auto layout */
    flex: none;
    order: 5;
    flex-grow: 0;
    color: #fff;
    margin-top: 15px;
    border: none;
}

.consent-container {
    display: flex;
    height: 30px;
    align-items: center;
}

.cntr_line {
    width: 100%;
    height: 0px;
    top: 10px;
    position: absolute;
    z-index: 1000;
}

.cntr_line2 {
    height: 0px;
    width: 100%;
    top: 90px;
    position: absolute;
    z-index: 1000;
}