

@media (min-width: 900px) {
    .cntr_footer {
        /* Rectangle 12 */
    
    
        width: 100%;
        height: 237px;
        display: flex;
        /* justify-content: center; */
        flex-direction: column;
        align-items: center;
        /* Blue */
        background: #333B5F;
    
    }

    .cntr_footer_row {
        margin-top: 40px;
        width: 1300px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .cntr_footer_row_mob {
        display: none;
    }

    .footer_cntr_links {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        width: 1300px;
    }

    .footer_cntr_menu {
        margin-top: 31px;
        display: flex;
        gap: 40px;
      }
}

@media (max-width: 900px) {
    .cntr_footer {
        /* Rectangle 12 */
    
    
        width: 100%;
        /* height: 237px; */
        display: flex;
        /* justify-content: center; */
        flex-direction: column;
        align-items: center;
        /* Blue */
        background: #333B5F;
        padding-bottom: 39px;
    
    }

    .cntr_footer_row {
        display: none;
    }

    .cntr_footer_row_mob {
        width: 320px;
    }

    .footer_cntr_links {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        width: 320px;
    }

    .footer_cntr_menu {
        margin-top: 31px;
        display: flex;
        gap: 40px;
        width: 300px;
      }
}



.footer_logo {
    width: 64px;
    height: auto;
}

.txt_cap_under {
    text-transform: uppercase;
    text-decoration: underline;
}

.footer_link_online {
    /* DIKIGOROS ONLINE */



    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    text-transform: uppercase;

    color: #A0A0A0;


}



.cntr_footer_line {
    position: relative;
}

