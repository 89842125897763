@media (max-width: 900px) {
    .legal-services {
        /* display: flex; */
        height: 80vh !important;
        width: 100vw;
        background-color: #f8f8f8;
        box-sizing: border-box;
        text-align: left;
        padding: 1vh 3vw 0 3vw !important;
        overflow-x: hidden;
    }
}

.legal-services {
    /* display: flex; */
    /* height: 80vh; */
    width: 100vw;
    background-color: #f8f8f8;
    box-sizing: border-box;
    text-align: left;
    padding: 20vh 3vw 0 3vw;
    overflow-x: hidden;
}

.legal-services h3, .legal-services h2 {
    color: #333;
    /* width: 70%; */

}

.legal-services p, .legal-services li {
    color: #555;
    line-height: 1.6;
    /* width: 70%; */
}

.legal-services ul {
    list-style-type: none;
    padding: 0;
    /* width: 80%; */
}

.legal-services li {
    margin-bottom: 10px;
}

.legal-services {
    color: #f8f8f8;
}

.legal-services strong {
    color: #000;
}
