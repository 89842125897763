@media (min-width: 900px) {
    .services_title_cntr {
        width: 100%;
        text-align: left;
        /* margin-left: 160px; */
    }

    .services_row {
        display: flex;
        width: 1300px;
        justify-content: space-between;
    }

    .services_cntr_img_mob {
        display: none;
    }

    .services_button {
        /* Frame 3 */

        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px 30px;
        /* Gold */
        background: #A78957;
        width: 1300px;
        border-radius: 0;
        color: #fff;
    }

    .cntr_login_widget {
        width: 420px;
        background-color: #333B5F;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        box-sizing: border-box;
    }

    .login_widget_title {
        margin-top: 103px;
        /* Online ΔικηγΟρος Chat */
        width: 333px;


        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 31px;
        text-align: center;
        text-transform: uppercase;

        /* White */
        color: #F5F5F5;


    }

    .login_widget_input {
        /* Frame 5 */
        outline: none;
        box-sizing: border-box;

        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 14px 15px;
        gap: 10px;

        width: 360px;
        height: 45px;
        color: #fff;

        border: 1px solid #DCDCDC;
        background: none;
    }

    .login_widget_button {
        /* Frame 3 */

        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 14px 30px;

        width: 360px;
        height: 45px;
        border-radius: 0;

        /* Gold */
        background: #A78957;
        box-sizing: border-box;
        color: #fff;

    }

    .login_widget_footer_text {
        /* Δίνω τη συγκατάθεσή μου για την επεξεργασία προσωπικών δεδομένων */

        width: 360px;
        height: 36px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        /* or 18px */
        text-align: center;

        color: #DCDCDC;

    }

    .cntr_service_text {
        position: absolute;
        bottom: 10px;
        left: 20px;
        text-align: left;
        height: 212px;
    }

    .service_title {

        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 31px;
        text-transform: uppercase;

        /* White */
        color: #F5F5F5;
        width: 246px;

    }

    .service_text {

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */

        color: #DCDCDC;
        margin-top: 20px;

    }
}

@media (max-width: 900px) {

    .service_title {

        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        text-transform: uppercase;

        /* White */
        color: #F5F5F5;
        width: 246px;
    }

    .service_text {

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        /* or 24px */

        color: #DCDCDC;
        margin-top: 20px;
    }

    .cntr_service_text {
        position: absolute;
        bottom: 10px;
        left: 20px;
        text-align: left;
        height: 190px;
    }

    .services_cntr {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .login_widget_title {
        margin-top: 103px;
        /* Online ΔικηγΟρος Chat */
        width: 230px !important;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 22px !important;
        line-height: 27px;
        text-align: center;
        text-transform: uppercase;

        /* White */
        color: #F5F5F5;
    }

    .cntr_login_widget {
        width: 320px !important;
        background-color: #333B5F;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        box-sizing: border-box;
    }

    .services_button {
        /* Frame 3 */

        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px 30px;
        /* Gold */
        background: #A78957;
        width: 320px !important;
        border-radius: 0;

        margin-top: 20px;
        color: #fff;
    }

    .services_title_cntr {
        width: 320px !important;
        text-align: left;
        margin-top: 80px;
        /* margin-left: 160px; */
    }

    .services_row {
        display: none !important;
    }

    .services_cntr_img_mob {
        width: 320px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 30px;
    }

    .login_widget_input {
        /* Frame 5 */
        outline: none;
        box-sizing: border-box;

        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 14px 15px;
        gap: 10px;

        width: 280px !important;
        height: 45px;
        color: #fff;

        border: 1px solid #DCDCDC;
        background: none;
    }

    .login_widget_button {
        /* Frame 3 */

        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 14px 30px;

        width: 280px !important;
        height: 45px;
        border-radius: 0;

        /* Gold */
        background: #A78957;
        box-sizing: border-box;

    }

    .login_widget_footer_text {
        /* Δίνω τη συγκατάθεσή μου για την επεξεργασία προσωπικών δεδομένων */

        width: 310px !important;
        height: 36px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        /* or 18px */
        text-align: center;

        color: #DCDCDC;

    }
}











.login_widget_input::placeholder {
    /* Email */



    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* identical to box height */

    /* White */
    color: #F5F5F5 !important;

}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #333B5F inset !important;
}