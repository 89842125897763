/* home.css */
@import '../../static/css/vars.css';





.logo {
  width: 64px;
  height: auto;
  position: absolute;
  top: 0px;
  left: 0;
}

.nav-menu {
  display: flex;
  gap: 20px;
  font-size: 1.2em;
  width: 1300px;
  align-items: flex-start;

  /* background: linear-gradient(to bottom, #18171e, #A9A4A1); */
  /* background: #221817ba */
}

.nav-item {
  cursor: pointer;

  font-size: 14px;
  font-family: "Inter", sans-serif;
}

.nav-item:hover {
  text-decoration: underline;
}


.nav-menu-mobile-chat {
  display: none;
  /* По умолчанию меню скрыто */
}

@media (min-width: 900px) {
  .instruction_cntr_item {
    /* Rectangle 15 */
    position: relative;
    width: 1300px;
    /* height: 80px; */
    padding: 24px 0 25px 30px;
  
    /* Blue */
    background: #333B5F;
  
    text-align: left;
  
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
  
    /* White */
    color: #F5F5F5;
  }
  .svg_globus {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .instruction_cntr_item_full {
    /* Η σελίδα δικηγόρος online έχει φτιαχτεί για να σας κάνει τη ζωή πιο εύκολη και στη δυσκολία  να βρίσκετε λύσεις οικονομικά και άμεσα. Έχουμε διάφορες επιλογές στη σελίδα μας για να βρείτε την κατάλληλη λύση για σας. Για πιο άμεση απάντηση λειτουργεί 24/7 το chat bot μας, και αν επιθυμείτε άμεση συνάντηση με τους δικηγόρους μας μπορείτε να επιλέξετε το κουμπί booking online και μέσα στις 24 h εγγυημένα θα έχετε online συνάντηση με ένα από έμπειρους δικηγόρους της πλατφόρμας μας. */
  
    width: 1240px;
    /* height: 96px; */
    padding: 24px 0 25px 30px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */
  
    /* Black */
    color: #1A1A1A;
  
    text-align: left;
  
  }
}

.cntr_menu {
  margin-top: 31px;
  margin-left: 158px;
  display: flex;
  gap: 40px;
}


@media (max-width: 900px) {
  .instruction_cntr_item_full {
    /* Η σελίδα δικηγόρος online έχει φτιαχτεί για να σας κάνει τη ζωή πιο εύκολη και στη δυσκολία  να βρίσκετε λύσεις οικονομικά και άμεσα. Έχουμε διάφορες επιλογές στη σελίδα μας για να βρείτε την κατάλληλη λύση για σας. Για πιο άμεση απάντηση λειτουργεί 24/7 το chat bot μας, και αν επιθυμείτε άμεση συνάντηση με τους δικηγόρους μας μπορείτε να επιλέξετε το κουμπί booking online και μέσα στις 24 h εγγυημένα θα έχετε online συνάντηση με ένα από έμπειρους δικηγόρους της πλατφόρμας μας. */
  
    width: 280px;
    /* height: 96px; */
    padding: 24px 0 25px 30px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */
  
    /* Black */
    color: #1A1A1A;
  
    text-align: left;
  
  }
  .instruction_cntr_item {
    /* Rectangle 15 */
    position: relative;
    width: 320px;
    /* height: 80px; */
    padding: 24px 0 25px 30px;
  
    /* Blue */
    background: #333B5F;
  
    text-align: left;
  
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 31px;
  
    /* White */
    color: #F5F5F5;
  }
  .chat_main_cntr {
    padding: 19px;
  }
  .svg_globus {
    /* transform: translateY(-40%); */
    position: absolute;
    right: 65px;
    top: 25%;
    cursor: pointer;
    display: flex;
  }



  .nav-menu-mobile-chat {
    display: block;
    /* Показываем меню только на устройствах с шириной до 900 пикселей */
    text-align: right;
    width: 100%;
    height: 14vh;
    /* background: linear-gradient(to bottom, #18171e, #A9A4A1); */
    background-color: #18171e;
    position: relative;
  }

  .nav-menu {
    display: none;
  }



  .burger-menu {
    /* margin-right: 10px;
    position: absolute;
    top: 20%; */
    right: 10px !important;
  }
}

.burger-menu {
  margin-right: 10px;
  position: absolute;
  top: 20%;
  right: 70px;
}






.svg_out {
  position: absolute;
  right: 25px;
  cursor: pointer;
  display: flex;
}




.language-code {
  font-size: 14px;
  font-family: "Inter", sans-serif;

}

.cntr_block2 {
  background-color: #d4b43d;
  height: 100vh;
  width: 100%;
}

.logo_menu {
  width: 64px;
  height: auto;
}





.svg_minus {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  display: flex;
  cursor: pointer;
}