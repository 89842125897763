@media (min-width: 900px) {
    .cntr_comments_item {
        /* Rectangle 8 */

        box-sizing: border-box;
        width: 420px;
        height: 365px;

        /* Blue */
        border: 1px solid #333B5F;
        text-align: left;
        padding: 30px;
    }

    .row_comments {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        width: 1300px;
    }

    .text_comments_item {
        /* Άριστος επαγγελματίας με κατάρτιση, εμπειρία και ήθος. Αδιαμφισβήτητο χάρισμα η ανθρωπιά και η ειλικρίνεια. Αξιόπιστος, με συνέπεια και εξαιρετικά αφοσιωμένος στην υπόθεση που είχε αναλάβει εκ μέρους μας. Υπήρξε μια άψογη συνεργασία. Εν κατακλείδι, αυτό που θέλω να εκφράσω είναι ένα ΕΥΧΑΡΣΤΩ και συνεχίστε με τον ίδιο σεβασμό να ασκείται το λειτούργημα της δικηγορίας. */


        margin-top: 5px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */

        color: #A0A0A0;

    }

    .row_comments_mob {
        display: none;
    }
}

@media (max-width: 900px) {
    .text_comments_item {
        margin-top: 20px;



        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */

        color: #A0A0A0;

    }

    .row_comments {
        display: none;
    }

    .row_comments_mob {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        width: fit-content;
    }

    .cntr_comments_item {
        /* Rectangle 8 */

        box-sizing: border-box;
        width: 320px;
        /* height: 340px; */

        /* Blue */
        border: 1px solid #333B5F;
        text-align: left;
        padding: 30px;
    }
}

.title_comments_item {
    /* Georgia Karamolegkou */



    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    text-transform: uppercase;

    /* Black */
    color: #1A1A1A;

}