@media (max-width: 900px) {
    .btn_online_b {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px 13px;
        gap: 10px;
    
        /* position: absolute; */
        width: 180px;
        height: 57px;
    
        /* Gold */
        background: #A78957;
        box-sizing: border-box;
        cursor: pointer;
    }
}

@media (min-width: 900px) {
    .btn_online_b {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px 30px;
        gap: 10px;
    
        /* position: absolute; */
        width: 214px;
        height: 57px;
    
        /* Gold */
        background: #A78957;
        box-sizing: border-box;
        cursor: pointer;
    }
}

