.cntr_block2 {
    background-color: #fff;
    height: 70vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}



.contact-container h1 {
    /* margin-bottom: 20px; */
    font-size: 4rem;
    text-align: left;
    color: #161a38;
}

.contact-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 40vw;
    align-items: end;
    gap: 10px;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
    background: transparent;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}

.contact-form textarea {
    resize: vertical;
    height: 100px;
}



.contact-form button:hover {
    background-color: #777a7b;
}

@media (min-width: 768px) {
    .contact-form button {
        width: 30%;
        padding: 10px;
        background-color: #161a38;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1rem;
    }
    .contact-form {
        flex-direction: row;
    }

    .contact-form input,
    .contact-form textarea {
        width: 100%;

    }

    .contact-container {
        display: flex;
        align-items: center;
        width: 80%;
        /* max-width: 800px; */
        background-color: #fff;
        padding: 20px;
        /* margin: 20px; */
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        border: #d4b43d solid 1px;
        border-radius: 10px;
    }

    .cntr_input {
        background-color: #5e5c6c;
        border-radius: 10px;
        width: 70%;
    }
    .btn_send {
        width: 10vw;
        height: 5vw;
    }
}

/* Mobile styles */
@media (max-width: 768px) {
    .contact-form button {
        width: 100%;
        padding: 10px;
        background-color: #161a38;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1rem;
    }
    .btn_send {
        width: 50vw;
        height: 15vw;
    }
    .cntr_input {
        background-color: #5e5c6c;
        border-radius: 10px;
        width: 100%;
    }


    .contact-form {
        flex-direction: column;
        width: 60vw !important;
        align-items: start !important;
    }

    .contact-form input,
    .contact-form textarea {
        width: 100%;

    }

    .contact-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        /* max-width: 800px; */
        background-color: #fff;
        padding: 20px;
        /* margin: 20px; */
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        border: #d4b43d solid 1px;
        border-radius: 10px;
    }

    .contact-container h1 {
        /* margin-bottom: 20px; */
        font-size: 2rem !important;
    }
}

.contact-form textarea {
    resize: none;
    /* Убирает уголок для изменения размера */
    /* height: 100px; */
    outline: none;
}

.contact-form input {
    outline: none;
}

.contact-form textarea::placeholder {
    color: #fff;
}

.contact-form input::placeholder {
    color: #fff;
}



.line {
    margin-left: 5%;
    height: 1px;
    width: 90%;
    /* background-color: #fff; */
    border-bottom: #fff solid 1px;
}