/* home.css */
@import '../../static/css/vars.css';

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  color: white;
  /* padding: 20px; */
  box-sizing: border-box;

  /* H1 mobile */
  font-family: 'Inter';
  font-style: normal;



}



.logo {
  width: 64px;
  height: auto;
  position: absolute;
  top: 0px;
  left: 0;
}

.nav-menu {
  display: flex;
  gap: 20px;
  font-size: 1.2em;
  width: 1300px;
  align-items: flex-start;
  z-index: 0;
  /* background: linear-gradient(to bottom, #18171e, #A9A4A1); */
  /* background: #221817ba */
}

.nav-item {
  cursor: pointer;

  font-size: 14px;
  font-family: "Inter", sans-serif;
  text-transform: uppercase;
}

.nav-item:hover {
  text-decoration: underline;
}


.nav-menu-mobile {
  display: none;
  /* По умолчанию меню скрыто */
}

@media (min-width: 900px) {
  .burger-menu-home {
    margin-right: 10px;
    position: absolute;
    top: 20%;
    right: 70px;
  }

  .svg_globus_home {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .language-menu {
    position: absolute;
    top: 30px;
    left: -13px;
    padding: 10px;
    z-index: 1000;
    width: 72px;

    background: rgba(41, 41, 41, 0.8);
    border-radius: 5px;

  }
}

.cntr_menu {
  margin-top: 31px;
  margin-left: 158px;
  display: flex;
  gap: 40px;
}


@media (max-width: 900px) {
  .home-container {
    display: block !important;
    width: 100vw;
  }
  .language-menu {
    position: absolute;
    top: 0px;
    left: 43px;
    padding: 10px;
    z-index: 1000;
    width: 72px;

    background: rgba(41, 41, 41, 0.8);
    border-radius: 5px;

  }

  .svg_globus_home {
    /* transform: translateY(-40%); */
    position: absolute;
    left: 75px;
    bottom: 15px;
    cursor: pointer;
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .home-container {
    z-index: 0;
    background-size: 200% !important;
    background-position: 0 14vh !important;
    /* Измените это значение в зависимости от ваших нужд */
    background-repeat: no-repeat;
  }

  .nav-menu-mobile {
    display: block;
    /* Показываем меню только на устройствах с шириной до 900 пикселей */
    text-align: right;
    width: 360px;
    height: 14vh;
    /* background: linear-gradient(to bottom, #18171e, #A9A4A1); */
    background-color: #18171e;
    position: relative;
  }

  .nav-menu {
    display: none;
  }

  .mobile-menu {
    /* Rectangle 19 */

    position: absolute;
    width: 260px;
    height: 550px;
    right: 0px;
    top: 0px;

    /* Blue */
    background: #333B5F !important;
    z-index: 1000;

    display: flex;
    flex-direction: column;
  }

  .burger-menu-home {
    /* margin-right: 10px;
    position: absolute;
    top: 20%; */
    right: 0px !important;
  }
}


.svg_out {
  position: absolute;
  right: 25px;
  cursor: pointer;
  display: flex;
}

.language-selector {
  position: relative;
}


.language-code {
  font-size: 14px;
  font-family: "Inter", sans-serif;

}





.language-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-align: left;
}

.language-menu li {
  padding: 8px 12px;
  cursor: pointer;
  /* color: #18171e; */
  /* Greek */

  /* width: 52px;
  height: 17px; */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #F5F5F5;

}

/* .language-menu li:hover {
  background-color: #f0f0f0;
} */

.cntr_block2 {
  background-color: #d4b43d;
  height: 100vh;
  width: 100%;
}

.logo_menu {
  width: 64px;
  height: auto;
}