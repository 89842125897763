/* auth.css */
.auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    height: 75vh;
    background-color: #F8F8FA;
    width: 100%;
    height: 100vh;
  }
  
  .auth-container h2 {
    margin-bottom: 20px;
    margin-top: 20vh;
  }
  
  .auth-container input {
    margin: 10px 0;
    padding: 10px;
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .auth-container button {
    padding: 10px 20px;
    margin-top: 10px;
    background-color: var(--secondary-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .auth-container button:hover {
    background-color: var(--accent-color);
  }
  
  .auth-container p {
    margin-top: 20px;
    color: var(--secondary-color);
    cursor: pointer;
  }
  