/* OnlineConsultant.css */
.consultant-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
  /* height: 100vh; */
  background-color: #F8F8FA;
  color: #ffffff;
  box-sizing: border-box;
}

.consultant_cntr_menu {
  display: flex;
  justify-content: center;
}

h2 {
  margin-bottom: 20px;
  color: #221817;
}



.send-button {
  width: 64px;
  height: 68px;

  /* Gold */
  background: #A78957;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
}

.send-button:hover {
  background-color: #5c596b;
}





.chat-message {
  /* Frame 28 */

  /* Auto layout */

  padding: 20px;

  background: #FFFFFF;


  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  /* Black */
  color: #1A1A1A;

}

.chat_message_user {

  padding: 20px;

  /* Blue */
  background: #333B5F;

}

.logo_msg {
  width: 40px;
  height: 40px;
}

.cntr_logo_msg {
  padding: 3px;
  /* width: 50px;
  height: 50px; */
  background: #333B5F;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.time_text {
  /* 12:09 */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  color: #A0A0A0;


}

.question {
  /* background-color: #e6f7ff; */
  align-self: flex-end;
  
  width: 60%;

}

.answer {
  display: flex;
  align-items: end;
  width: 60%;
}



p {
  margin: 0;
  padding: 8px;
}





.bg_menu {
  background: #333B5F;

}



.chat_title2 {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  color: #A0A0A0;

}



@media (max-width: 900px) {
  .text-area {
    /* flex-grow: 1;
    min-height: 50px;
    resize: none;
    margin-right: 10px;
    width: 60vw; */
    /* Frame 28 */
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 10px;
  
    /* White */
    background: #F5F5F5;
    resize: none;
    width: 80%;
    height: 64px;
    outline: none;
    border: none;
    margin-top: 0 !important;
  
  }
  .input-container {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    width: 360px;
    gap: 20px;
  }
  .chat-container {
    padding: 40px;
    width: 360px;
    height: 851px;
  
    /* White */
    background: #F5F5F5;
    margin-top: 140px;
    box-sizing: border-box;
  }
  .chat_title1 {
    width: 320px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 70px;
    line-height: 110%;
    /* or 77px */
    text-transform: uppercase;
  
    /* Black */
    color: #1A1A1A;
  }
  .cons_cntr_title {
    width: 360px;
    margin-top: 277px;
    text-align: left;
  }
}

@media (min-width: 900px) {
  .text-area {
    /* flex-grow: 1;
    min-height: 50px;
    resize: none;
    margin-right: 10px;
    width: 60vw; */
    /* Frame 28 */
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    gap: 10px;
    margin-top: 0 !important;
    color: #1A1A1A;
    /* White */
    background: #F5F5F5;
    resize: none;
    width: 90%;
    height: 64px;
    outline: none;
    border: none;
  
  }
  .input-container {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 1300px;
    gap: 20px;
  }
  .chat-container {
    padding: 40px;
    width: 1300px;
    height: 851px;
  
    /* White */
    background: #F5F5F5;
    margin-top: 140px;
    box-sizing: border-box;
  }
  .chat_title1 {
    width: 800px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 70px;
    line-height: 110%;
    /* or 77px */
    text-transform: uppercase;
  
    /* Black */
    color: #1A1A1A;
  }
  .cons_cntr_title {
    width: 1300px;
    margin-top: 277px;
    text-align: left;
  }
}